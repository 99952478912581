<template>
  <v-container fluid>
    <Breadcrumbs />
    <v-layout row wrap>
      <v-flex xs12 sm12 md12>
        <v-row class="justify-center" style="margin: 0px">
          <v-col cols="12" md="8" sm="10" lg="6">
            <v-card class="secondarygrad ma-5 pa-4 rounded">
              <v-card-title class="justify-center">Choose Dialer</v-card-title>
              <v-card-text style="padding: 20px">
                <div style="font-size: 14px;" align="center">
                  GoDial allows you to place calls via your phone's dialer or
                  from any of the following apps.
                </div>
                <v-list class="transparent">
                  <v-radio-group v-model="selectedDialer">
                    <template v-for="(item, index) in dialerLists">
                      <v-list-item :key="index">
                        <v-list-item-avatar>
                          <v-img :src="item.logo"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-radio :value="`${item.name}`"></v-radio>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-radio-group>

                  <v-flex xs12 sm12 md12 style="text-align: center">
                    <v-btn
                      color="primarygrad white--text"
                      @click="setDefaultDialer"
                      >Set</v-btn
                    >
                  </v-flex>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog v-model="loader" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "../components/breadcrumbs.vue";
export default {
  data() {
    return {
      loader: false,
      selectedDialer: "Phone Dialer",
      dialerLists: [
        {
          name: "Phone Dialer",
          logo: require("../assets/dialers/dialer.png"),
          package: "",
          ios: true,
          android: true,
          desktop: true,
        },
        {
          name: "FaceTime",
          logo: require("../assets/dialers/facetime.png"),
          package: "",
          ios: true,
          android: false,
          desktop: true,
        },

        {
          name: "Vonage",
          logo: require("../assets/dialers/vonage.png"),
          package: "com.vocalocity.Administration",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Bria",
          logo: require("../assets/dialers/bria.png"),
          package: "com.counterpath.bria",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Finera MobileVOIP",
          logo: require("../assets/dialers/finera.png"),
          package: "finarea.MobileVoip",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Truecaller",
          logo: require("../assets/dialers/truecaller.png"),
          package: "",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Skype",
          logo: require("../assets/dialers/skype.png"),
          package: "",
          ios: true,
          android: true,
          desktop: true,
        },
        {
          name: "Google Hangouts",
          logo: require("../assets/dialers/hangout.png"),
          package: "com.google.android.apps.hangoutsdialer",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Magic Jack",
          logo: require("../assets/dialers/magicjack.png"),
          package: "com.magicjack",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Zoiper",
          logo: require("../assets/dialers/zoiper.png"),
          package: "com.zoiper.android.app",
          ios: true,
          android: true,
          desktop: true,
        },
        {
          name: "Line",
          logo: require("../assets/dialers/line.png"),
          package: "jp.naver.line.android",
          ios: true,
          android: true,
          desktop: false,
        },
        {
          name: "Viber",
          logo: require("../assets/dialers/viber.png"),
          package: "com.viber.voip",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "2ndLine",
          logo: require("../assets/dialers/2ndline.png"),
          package: "com.enflick.android.tn2ndLine",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Satellite",
          logo: require("../assets/dialers/satellite.png"),
          package: "de.sipgate.app.satellite",
          ios: false,
          android: true,
          desktop: false,
        },
        {
          name: "Knowlarity",
          logo: require("../assets/dialers/knowlarity.png"),
          package: "",
          ios: true,
          android: true,
          desktop: true,
        },
        {
          name: "Exotel",
          logo: require("../assets/dialers/Exotel.png"),
          package: "",
          ios: true,
          android: true,
          desktop: true,
        },
        {
          name: "Telecmi",
          logo: require("../assets/dialers/telecmi.png"),
          package: "",
          ios: true,
          android: true,
          desktop: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(["ENDPOINT", "companyDetails"]),
  },
  components: {
    Breadcrumbs,
  },
  async created() {
    try {
      await this.refresh();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    // Load this page
    async refresh() {
      try {
        var filter = {
          include: [{ relation: "plan", scope: { include: ["coupons"] } }],
        };
        var response = await this.$http.get(
          `${this.$store.state.ENDPOINT}/companies/${
            this.$store.getters.user.companyId
          }?filter=${encodeURIComponent(JSON.stringify(filter))}`
        );
        response = response.body;
        console.log("response.body", response);
        this.$store.commit("setCompany", response); // store this company details
        this.selectedDialer = response.defaultDialer;
      } catch (e) {
        console.log("Error", e);
      }
    },
    async setDefaultDialer() {
      const self = this;
      console.log("this.selectedDialer", self.selectedDialer);

      console.log("companyDetails", self.companyDetails);

      self.loader = true;

      self.$http
        .patch(
          `${self.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          {
            defaultDialer: self.selectedDialer,
          }
        )
        .then((response) => {
          self.loader = false;
          console.log("response", response.body);

          self.$swal({
            type: "success",
            text: "Default dialer set is successfully",
          });

          self.refresh();
        })
        .catch((err) => {
          console.log("err", err);
          self.loader = false;
          self.$swal({
            type: "error",
            text: "Something went wrong. Try again later",
          });
        });
    },
  },
};
</script>
<style scoped>
.v-divider {
  margin: 5px 0px 25px 0px;
}
</style>
